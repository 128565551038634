import React, {useState} from "react";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import {Box, useMediaQuery} from "@material-ui/core";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "white",
        fontSize: "40px",
        marginRight: "15px",
    },
}));

const navigator = {
    hidden: {
        y: -100,
        height: "0px",
        transition: {
            duration: 0.75,
            ease: "easeOut",
        },
    },
    visible: {
        y: 0,
        height: "100%",
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delayChildren: 0.3,
            staggerChildren: 0.1
        },
    }
};

const naviItem = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    }
}


const Nav = () => {
    const {pathname} = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const matches = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    const toggleNavi = () => {
        setIsOpen(!isOpen)
    }

    return (
        <StyledNav>
            <Box display={"flex"} width={matches && "100%"} justifyContent={!matches ? "center" : "space-between"}
                 alignItems={"center"}>
                <h1>
                    <Link id="logo" to={"/"}>
                        Fell-fabrik.de 0151/64544682
                        {/*<img src="./img/logo.png" alt="Fell-Fabrik.de"/>*/}
                    </Link>
                </h1>
                {matches &&
                <>
                    {isOpen
                        ?
                        <CloseOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                        :
                        <MenuOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                    }
                </>
                }
            </Box>
            <motion.ul variants={navigator} animate={(isOpen || !matches) ? "visible" : "hidden"} initial={"hidden"}
                       style={matches && {paddingTop: "0px"}}>
                <motion.li variants={naviItem}>
                    <Link to="/">Startseite</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/" ? "50%" : "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <Link to="/aboutme">Über mich</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/aboutme" ? "50%" : "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <Link to="/leistungen">Leistungen</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/leistungen" ? "50%" : "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <Link to="/fotos">Fotogalerie</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/fotos" ? "50%" : "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <Link to="/kontakt">Kontakt</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/kontakt" ? "50%" : "0%"}}
                    />
                </motion.li>
            </motion.ul>
        </StyledNav>
    );
};

const StyledNav = styled.nav`
  border-bottom: 2px solid var(--main-accent-color);
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background: var(--main-bg-color-sec);
  position: sticky;
  top: 0;
  z-index: 10;
  a {
    color: var(--main-text-color);
    text-decoration: none;
    font-size: 1.3rem;
  }
  ul {
    display: flex;
    list-style: none;
  }
  #logo {
    font-size: 1.75rem;
    // font-family: Aclonica,"Lobster", cursive;
    font-weight: lighter;
  }
  li {
    padding-left: 10rem;
    position: relative;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 2rem 1rem;
    #logo {
      display: inline-block;
      margin: 1rem;
    }
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
  @media (max-width: 600px) {
    ul {
      flex-direction: column;
      padding: 2rem;
      padding-top: 1rem;
      padding-bottom: 0px;
      align-items: center;
      
      li {
        padding-bottom: 2rem;
      }
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.3rem;
  background: var(--main-accent-color);
  width: 0%;
  position: absolute;
  bottom: -80%;
  left: 60%;
  @media (max-width: 1300px) {
    left: 0%;
  }
  
  @media (max-width: 600px) {
    bottom: 30%; 
  }
`;

export default Nav;
