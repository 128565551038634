import React from 'react';
import {Button, Flex, Image} from "@aws-amplify/ui-react";

function DirectPayment() {
    return (
        <>
            <Flex width={"100%"} alignItems={"center"} justifyContent={"center"} direction={"column"}>
                <Button onClick={() => window.open("https://buy.stripe.com/eVa9AF0nqaksaxW6oo", "_blank")} variation={"primary"} size={"large"} fontSize={"xxl"} marginTop={"large"}>Direct zum Checkout</Button>
                <Image src={"/img/paymentQR.png"} padding={"xxxl"}/>
            </Flex>
        </>
    )
}

export default DirectPayment