import React from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import {Box, useMediaQuery} from "@material-ui/core";

function Impressum() {
    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box width={!matches ? "50%" : "100%"} margin={"0 auto"}>
                    <h2>Impressum</h2>
                    <br/>
                    <LittleHeading>Angaben gemäß § 5 TMG</LittleHeading>
                    <p>Melanie Ketzler-Duscha<br/>
                        Achterholzkamp, 3<br/>
                        23795 Negernbötel (Hamdorf) </p>

                    <LittleHeading>Kontakt</LittleHeading>
                    <p>Mobil:  0151/64544682<br/>
                        E-Mail: fell-fabrik@gmx.de</p>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const LittleHeading = styled.strong`
font-size: 20px;
border-bottom: 2px solid var(--main-accent-color);
`

export default Impressum