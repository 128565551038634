import React from "react";
import {scrollReveal} from "../animation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {useMediaQuery} from "@material-ui/core";

function Ansprechpartner() {
    const matches400 = useMediaQuery('(max-width:400px)');

    return (
        <>
            <Styling className={"ansprechpartner"} variants={scrollReveal} style={{padding: matches400 && "0 35px"}}>
                    <div className={"flex-row"}>
                        <div className={"icon"}>
                            <img alt="icon" src={"./img/icons/telphoneicon.svg"}/>
                        </div>
                        <a href="tel:015164544682">
                            <p>0151 / 645 44 682</p>
                        </a>
                    </div>
                    <div className={"flex-row"}>
                        <div className={"icon"}>
                            <img alt="icon" src={"./img/icons/emailicon.svg"}/>
                        </div>
                        <a href="mailto:Fell-Fabrik@gmx.de">
                            <p>Fell-Fabrik@gmx.de</p>
                        </a>
                    </div>
            </Styling>
        </>
    )
}

const Styling = styled(motion.div)`
padding: 0 75px;
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
height: 100%;
flex-direction: column;
        
.icon {
    height: 50px;
    width: 50px;
}
        
a {
    text-decoration: none;
}
        
.flex-row {
    display: flex;
    justify-conent: center;
    align-items: center;
            
    p {
        padding-left: 30px;
    }
}
`

export default Ansprechpartner