import React from "react";
import ScrollTop from "../components/ScrollTop";
import {pageAnimation, scrollReveal} from "../animation";
import {motion} from "framer-motion";
import {Box, Paper, useMediaQuery} from "@material-ui/core";
import Qualifier from "../components/Qualifier";
import styled from "styled-components";
import KontaktButton from "../components/KontaktButton";
import FAQSection from "../components/FAQSection";

function AboutUs() {

    const matches1000 = useMediaQuery('(max-width:1000px)');
    const matches600 = useMediaQuery('(max-width:600px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"}
                     style={{backgroundImage: "url('./img/mainPic.jpg')", backgroundSize: "cover"}} flexDirection={matches1000 && "column"}>
                    <img style={{padding: !matches1000 ? "50px" : "20px 20px 0px 20px", margin: !matches1000 ? "30px" : "0px", height: "100%", width: !matches1000 ? "35%" : "100%"}} src="./img/logo.png"
                         alt="logo"/>
                    <Box display={"flex"} justifyContent={"center"} width={!matches1000 ? "35%" : "95%"} alignItems={"center"}
                         flexDirection={"column"} marginBottom={matches1000 && "5%"}>
                        <motion.div variants={scrollReveal}>
                            <Paper elevation={20} style={{padding: "25px"}}>
                                <h1 style={{borderBottom: "3px solid var(--main-accent-color)"}}>Ihr Hundefriseur in
                                    Negernbötel</h1>
                                <p>Herzlich willkommen in Ihrem neuen Hundesalon in Negernbötel. Ich behandel Ihren Hund
                                    fachgerecht mit den richtig abgestimmten Pflegeprodukten für das Fell Ihres Hundes
                                    und
                                    wasche und föhne von Hand (ohne Föhnbox). Außerdem Schere ich mit der Schermaschine
                                    auf
                                    die
                                    gewünschte Länge, schneide mit der Schere und modelliere für ein natürliches
                                    Aussehen.</p>
                                <KontaktButton to={"/kontakt"}/>
                            </Paper>
                        </motion.div>
                    </Box>
                </Box>
                <Box style={{backgroundColor: "var(--main-bg-color-sec)"}}>
                    <QualifiersStyle animate={"visible"} initial={"hidden"} style={{flexDirection: matches600 && "column", alignItems: matches600 && "center"}}>
                        <Qualifier text={"Günstige als andere: Check"} imgUrl={"/img/guenstig.svg"}/>
                        <Qualifier text={"Vertrauen & Gute Erfahrungen sammeln: Check"} imgUrl={"/img/bewertung.svg"}/>
                        <Qualifier text={"Top Schnitt, der Ihnen auch gefällt: Check"} imgUrl={"/img/schnitt.svg"}/>
                    </QualifiersStyle>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={!matches600 ? "75%" : "100%"}
                         padding={!matches600 ? "50px" : "20px"}>
                        <FAQSection/>
                    </Box>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const QualifiersStyle = styled(motion.div)`
padding-top: 4%;
padding-bottom: 4%;
display: flex;
flex: 1 1 25%;
width: 100%;
justify-content: space-around;
align-items: baseline;
height: 100%;
`

export default AboutUs