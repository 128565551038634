import React, {useState} from "react";
import {fade, scrollReveal} from "../animation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {Box, useMediaQuery} from "@material-ui/core";
import CustomCheckbox from "./CustomCheckbox";

function KontaktForm() {
    const [data, setData] = useState({
        name: "",
        tel: "",
        message: "",
    })

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // eslint-disable-next-line no-restricted-globals
        location.href = `mailto:Fell-Fabrik@gmx.de?subject=Nachricht ${data.name}&body=Tel: ${data.tel}%0A%0A${data.message}`;
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const matches400 = useMediaQuery('(max-width:400px)');

    return (
        <>
            <Styling className={"form"} variants={scrollReveal} style={{padding: matches400 && "0 35px"}}>
                <form onSubmit={handleFormSubmit}>
                    <Box>
                        <input type="text" className={"inputField"} placeholder={"Name"} name={"name"}
                               onKeyUp={handleChange} required/>
                        <input type="tel" className={"inputField"} placeholder={"Telefon-Nr."} name={"tel"}
                               onKeyUp={handleChange} required/>
                        <textarea style={{width: "100%", resize: "vertical", minHeight: "200px"}}
                                  className={"inputField"} placeholder={"Nachricht"} minLength={"5"}
                                  required={true} name={"message"} onKeyUp={handleChange}/>
                        <CustomCheckbox required={true} defaultChecked={false} text={"Ich erkläre mich mit der Verarbeitung der eingegebenen Daten sowie der Datenschutzerklärung einverstanden.*"}/>
                    </Box>
                    <motion.button type={"submit"} style={{marginBottom: "7%", marginTop: "3%"}} variants={fade}>Verschicken</motion.button>
                </form>
            </Styling>
        </>
    )
}

const Styling = styled(motion.div)`
width: 100%;
height: 100%;
padding: 0 75px;
margin-top: 3%;
        
form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
            
    .inputField {
        padding: 15px 20px;
        background-color: var(--main-bg-color);
        border: 2px solid var(--main-bg-color-sec);
        outline: none;
        transition: 0.2s;
        color: var(--other-color);
        font-size: 18px;
        margin: 15px 0;
        width: 100%;
        resize: vertical;
        margin-right: 20px;
        border-radius: 5px;
    
        &:focus {
            border: 2px solid var(--main-accent-color) !important;
        }
    
        &:invalid {
            border: 2px solid red;
        }
    }
          
    .subButton {
        font-size: 25px;
        padding: 10px 25px;
        background-color: var(--main-bg-color-sec);
        margin: 15px auto;
        width: 50%;
        outline: none;
        border: none;
        color: var(--other-color);
        border-radius: 5px;
        -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        transition: 0.3s;

        &:active {
            -webkit-box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
        }
    
        &:hover {
            color: var(--main-accent-color);
        }
    }
}
`

export default KontaktForm