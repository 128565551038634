import React from "react";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {motion} from "framer-motion";
import {Box, useMediaQuery} from "@material-ui/core";
import Leistung from "../components/Leistung";
import BathtubIcon from '@material-ui/icons/Bathtub';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import BugReportIcon from '@material-ui/icons/BugReport';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PetsIcon from '@material-ui/icons/Pets';

function Leistungen() {
    const matches600 = useMediaQuery('(max-width:600px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
                style={{height: "100%"}}
            >
                <motion.div>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"flex-start"} height={!matches600 && "90vh"}
                         width={"100%"}>
                        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} flexWrap={"wrap"}
                             height={"100%"} width={!matches600 ? "80%" : "100%"}>
                            <Leistung title={"Carden"}
                                      desc={"Erhalten Sie die gesunde Fellstruktur des Hundes. Beim Carden  wird die komplette Unterwolle herausgeholt, das Fell selber bleibt erhalten. Die Haut wird wieder besser belüftet."}
                                      icon={<ClearAllIcon fontSize={"large"}/>}/>
                            <Leistung title={"Trimmen"}
                                      desc={"Beim Trimmen wird das abgestorbene Fell fachgerecht mit der Hand herausgezogen. Der Hund wird sich nicht mehr soviel kratzen um das Fell loszuwerden. Bei regelmäßigen Trimmen erreicht man einen sogenannten Rolling-Coat"}
                                      icon={<BugReportIcon fontSize={"large"}/>}/>
                            <Leistung title={"Schneiden"}
                                      desc={"Das Fell wird mit Schere, Effilierschere oder Schermaschine in Form gebracht. Bei weicher Fellbeschaffenheit neigen Hunde zum Verfilzen."}
                                      icon={<MergeTypeIcon fontSize={"large"}/>}/>
                            <Leistung title={"Allgemeines"} desc={"Entwollen, Entfilzen"}
                                      icon={<PetsIcon fontSize={"large"}/>}/>
                            <Leistung title={"Wasser"} desc={"Baden/Föhnen, Kämmen"}
                                      icon={<BathtubIcon fontSize={"large"}/>}/>
                            <Leistung title={"Pflege"} desc={"Augen-, Ohren-, Krallenpflege"}
                                      icon={<LocalHospitalIcon fontSize={"large"}/>}/>
                        </Box>
                    </Box>
                </motion.div>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default Leistungen