import React from "react";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import styled from "styled-components";

function KontaktButton({to}) {
    return (
        <>
            <Style>
                <Link to={to}>
                    <Button variant={"contained"} size={"large"}>
                        Kontakt
                    </Button>
                </Link>
            </Style>
        </>
    )
}

const Style = styled.div`
a {
    text-decoration: none;
}
`

export default KontaktButton