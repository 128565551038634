export const faqs = [
    {
        question: "Wie teuer ist der Schnitt?",
        answer: [
            "Jeder Hund ist anders, jeder Hund wird sorgfältig und gewissenhaft von mir gepflegt.",
            "Nur mal eben scheren gibt es bei mir nicht. Deshalb ist eine vorherige Aussage über den Preis schwierig ohne den Hund gesehen zu haben.",
        ]
    },
    {
        question: "Wie viel Zeit muss ich einplanen?",
        answer: [
            "Da jeder Hund unterschiedlich Zeit braucht, ist das schwer zu sagen. Häufig ca. 30 Min. - 2 Stunden.",
        ]
    },
]
