import React from "react";
import {Box, Button, Paper, useMediaQuery} from "@material-ui/core";
import {pageAnimation, scrollReveal} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {motion} from "framer-motion";
import KontaktButton from "../components/KontaktButton";

function AboutMe() {
    const matches1000 = useMediaQuery('(max-width:1000px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box display={"flex"} justifyContent={"center"} alignItems={!matches1000 ? "flex-start" : "center"} height={!matches1000 && "80vh"}
                     width={"100vw"}
                     margin={"25px 0"} flexDirection={matches1000 && "column"}>
                    <img
                        // src="https://cdn02.plentymarkets.com/fzraxqjvapo5/item/images/777784373/full/Cyroline-DojaKL20-777784373.jpg"
                        // src="https://via.placeholder.com/621x909"
                        src="/img/me.jpeg"
                        style={{height: "100%",padding: matches1000 && "50px", width: matches1000 && "100%", marginRight: !matches1000 && "5%", marginBottom: matches1000 && "5%"}} alt="me"/>
                    <Box display={"flex"} flex={"0 0 50%"} height={"100%"} justifyContent={"flex-start"}
                         alignItems={"flex-start"} flexDirection={"column"} margin={matches1000 && "0 25px"}>
                        <motion.div variants={scrollReveal}>
                            <Paper elevation={10} style={{padding: "25px"}}>
                                <h1 style={{borderBottom: "3px solid var(--main-accent-color)"}}>Über mich</h1>
                                <p>Ich habe in früher Kindheit meine Liebe zu Hunden entdeckt, da ich mit Hunden
                                    aufgewachsen bin.</p>
                                <p style={{padding: "0px"}}>Vor 13 Jahren kam dann mein erster Havaneser zu mir und im
                                    Laufe
                                    der Jahre fühlen sich
                                    nun schon 4 süße Havaneser sehr wohl bei uns. Damit begann auch das Lernen, wie
                                    Pflege
                                    ich einen langhaarigen Hund. Ich habe mir im Laufe der Jahre sehr viel Wissen
                                    angeeignet, was mir jedoch nicht gereicht hat. Somit beschloß ich eine Ausbildung
                                    zum
                                    Groomer zu machen. Diese habe ich erfolgreich abgeschlossen und möchte nun mein
                                    Wissen
                                    auch bei Ihrem vierbeinigen Liebling anwenden.</p>
                                <p>Überzeugen Sie sich selbst und vereinbaren einen Termin bei dem wir besprechen
                                    können,
                                    welche Pflege, Schnitt oder ähnliches für Ihren Vierbeiner das Richtige ist.</p>
                                <KontaktButton to={"/kontakt"}/>
                            </Paper>
                        </motion.div>
                    </Box>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default AboutMe