import React from "react";
import styled from "styled-components";
// import {About} from "../styles";
import Toggle from "./Toggle";
import {AnimateSharedLayout} from "framer-motion";
import {faqs} from "../faq";
import {motion} from "framer-motion";

const FAQSection = () => {
    return (
        <Faq>
            <h2>
                Häufige Fragen <span>FAQ</span>
            </h2>
            <AnimateSharedLayout>
                {
                    faqs.map(faq => {
                        return (
                            <Toggle title={faq.question}>
                                <div className="answer">
                                    {
                                        faq.answer.map(answer => {
                                            return (
                                                <p>{answer}</p>
                                            )
                                        })
                                    }
                                </div>
                            </Toggle>
                        )
                    })
                }
            </AnimateSharedLayout>
        </Faq>
    );
};

const Faq = styled(motion.div)`
  display: block;
  width: 100%;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default FAQSection;
