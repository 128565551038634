import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

:root {
  --main-bg-color: #F4EEFF;
  --main-bg-color-sec: #DCD6F7;
  --main-accent-color: #f38bb3;
  --main-text-color: #4E0505;;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    @media (max-width: 1700px){
        font-size: 75%;
    }
    
    @media (max-width: 400px){
        font-size: 60%;
    }
    
    scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-bg-color-sec);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-bg-color);
}

body{
    background: var(--main-bg-color);
    font-family: MyLoboster;
    overflow-x: hidden;
}

button {
    outline: none;
    font-weight: bold;
    font-size: 1.1.rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid var(--main-accent-color);
    background: transparent;
    color: black;
    transition: all 0.5s ease;
    
    &:hover{
        background-color: var(--main-accent-color);
        color: var(--main-text-color);
    }
}
h1{
    color: var(--main-text-color);
}
    h2{
        font-weight: lighter;
        font-size: 4rem;
        color: var(--main-text-color);
    }
    h3{
        color: var(--main-text-color);
    }
    h4{
        font-weight: bold;
        font-size: 2rem;
       
    }
    a{
        font-size: 1.1rem;
        color: var(--main-text-color);
    }
    span{
        font-weight: bold;
        color: var(--main-accent-color);
    }
    p{
        padding: 3rem 0rem;
        color: var(--main-text-color);
        font-size: 1.4rem;
        line-height: 150%;
        font-family: "Open Sans";
    }

`;

export default GlobalStyle;
