import React from "react";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {motion} from "framer-motion";
import {Box, Paper, useMediaQuery} from "@material-ui/core";
import Ansprechpartner from "../components/Ansprechpartner";
import KontaktForm from "../components/KontaktForm";

function Kontakt() {
    const matches900 = useMediaQuery('(max-width:900px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box display={"grid"} gridGap={!matches900 ? "50px" : "20px"} margin={!matches900 ? "50px" : "10px"} gridTemplateAreas={!matches900 ? "'telefon telefon telefon form form form'\n" +
                "    'telefon telefon telefon form form form'\n" +
                "    'maps maps maps maps maps maps'\n" +
                "    'maps maps maps maps maps maps'" : "'telefon'\n" +
                    "    'telefon'\n" +
                    "    'form'\n" +
                    "    'form'\n" +
                    "    'maps'\n" +
                    "    'maps'"
                }>
                    <Paper elevation={15} style={{gridArea: "telefon"}}>
                        <Ansprechpartner />
                        {/*    Telefon, E-Mail, ...*/}
                    </Paper>
                    <Paper elevation={15} style={{gridArea: "form"}}>
                        <KontaktForm />
                        {/* Kontakt Formular */}
                    </Paper>
                    <Paper elevation={15} style={{gridArea: "maps"}}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2346.22871591963!2d10.27535605152517!3d53.98096293458908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b22f18e96d4293%3A0x69c90601ee24a4ae!2sFell-Fabrik!5e0!3m2!1sde!2sde!4v1631465817492!5m2!1sde!2sde"
                            width="100%" height="700" style={{border: "0px"}} allowFullScreen="" loading="lazy"></iframe>
                        {/*    MAPS */}
                    </Paper>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default Kontakt