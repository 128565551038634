import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {Avatar, useMediaQuery} from "@material-ui/core";

function Qualifier({text, imgUrl}) {
    const qualiItem = {
        hidden: {
            x: 100,
            opacity: -0.5,
            transition: {
                duration: 1,
                ease: "easeOut",
            },
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
            },
        }
    };

    const matches600 = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Style style={{width: matches600 && "50%"}}>
                <motion.div variants={qualiItem} className={"itemContainer"}>
                    <Avatar className={"avatar"} style={{borderRadius: "0px", height: "40%", width: "40%"}} src={imgUrl}/>
                    <p>{text}</p>
                </motion.div>
            </Style>
        </>
    )
}

const Style = styled.div`
width: 20%;

.avatar {
    position: relative;
    height: 100px;
    width: 100px;
}
    
.itemContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    white-space: pre-wrap;
    width: 100%;
    text-align: center;
    position: relative;
}
`

export default Qualifier