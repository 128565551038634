import React from "react";
import styled from "styled-components";

function CustomCheckbox({text, defaultChecked, value, name, onClick, required, checked}) {
    return (
        <>
            <CustomCheckboxStyle className={"checkBoxText"}>
                <small>{text}</small>
                <input type={"checkbox"} defaultChecked={defaultChecked} value={value} name={name}
                       onClick={onClick}
                       checked={checked}
                       className={"checkBox"} required={required}/>
                <span className="checkmark"/>
            </CustomCheckboxStyle>
        </>
    )
}

const CustomCheckboxStyle = styled.label`
display: block;
  position: relative;
  padding-left: 35px;
  margin: 15px 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          background-color: var(--main-accent-color);

          &:after {
            display: block;
          }
        }
      }
    }

    &:invalid ~ .checkmark {
      border: 1px solid red;
    }
  }

  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: var(--main-bg-color);
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid var(--main-bg-color);
    transition: 0.2s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid var(--other-color);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
`;

export default CustomCheckbox