import React from "react";
//Global Style
import GlobalStyle from "./components/GlobalStyle";
import {AnimatePresence} from "framer-motion";
import {Box, createTheme} from "@material-ui/core";
import {ThemeProvider} from '@mui/material/styles';
import {Link, Route, Switch, useLocation} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Nav from "./components/Nav";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import AboutMe from "./pages/AboutMe";
import Leistungen from "./pages/Leistungen";
import Fotogalerie from "./pages/Fotogalerie";
import Kontakt from "./pages/Kontakt";
import Copyright from "./components/Copyright";
import '@aws-amplify/ui-react/styles.css';
import DirectPayment from "./pages/DirectPayment";
import MyDialog from "./components/MyDialog";

function App() {
    const location = useLocation();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#f38bb3',
            },
            secondary: {
                main: '#F4EEFF',
            },
        },
    });

    let currentDate = new Date();
    let startDate = new Date("2024-09-20");
    let endDate = new Date("2024-11-15");

    return (
        <div className="App">
            <GlobalStyle/>

            <Copyright/>
            <ThemeProvider theme={theme}>
                {currentDate >= startDate && currentDate <= endDate &&
                    <MyDialog/>
                }
                <Nav/>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/" exact>
                            <AboutUs/>
                        </Route>
                        <Route path="/aboutme" exact>
                            <AboutMe/>
                        </Route>
                        <Route path="/leistungen">
                            <Leistungen/>
                        </Route>
                        <Route path="/fotos">
                            <Fotogalerie/>
                        </Route>
                        <Route path="/kontakt">
                            <Kontakt/>
                        </Route>
                        <Route path="/impressum">
                            <Impressum/>
                        </Route>
                        <Route path="/datenschutz">
                            <Datenschutz/>
                        </Route>
                        <Route path="/admin">
                            <DirectPayment/>
                        </Route>
                    </Switch>
                </AnimatePresence>
                <Box display={"flex"} width={"100%"} alignItems={"center"}>
                    <Link to={"/impressum"} style={{color: "var(--main-text-color)", textdecoration: "none"}}>
                        Impressum
                    </Link>
                    <Link to={"/datenschutz"}
                          style={{marginLeft: "20px", color: "var(--main-text-color)", textdecoration: "none"}}>
                        Datenschutz
                    </Link>
                </Box>
            </ThemeProvider>
        </div>
    );
}

export default App;
