import React from "react";
import Flippy, {BackSide, FrontSide} from "react-flippy/dist/index.esm";
import {Box, useMediaQuery} from "@material-ui/core";

function Leistung({icon, title, desc}) {
    const matches900 = useMediaQuery('(max-width:900px)');
    const matches1300 = useMediaQuery('(max-width:1300px)');

    return (
        <>
            <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                style={{width: '200px', height: '200px', flex: !matches900 ? "1 1 45%" : "1 1 100%", marginRight: !matches900 && "20px"}}
            >
                {/*https://www.hundefriseurpeti.de*/}
                <FrontSide
                    style={{
                        backgroundColor: !matches900 ? 'var(--main-bg-color-sec)' : 'var(--main-bg-color)',
                    }}
                >
                    <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} height={"100%"} width={"100%"}>
                        {icon}
                        <p>{title}</p>
                    </Box>
                </FrontSide>
                <BackSide
                    style={{backgroundColor: 'var(--main-bg-color)'}}>
                    <Box display={"flex"} justifyContent={"space-around"} flexDirection={matches900 && "row-reverse"} alignItems={"center"} height={"100%"} width={"100%"}>
                        <p style={{width: !matches1300 ? "50%" : "70%"}}>{desc}</p>
                        <p style={{borderBottom: "5px solid var(--main-accent-color)"}}>{title}</p>
                    </Box>
                </BackSide>
            </Flippy>
        </>
    )
}

export default Leistung