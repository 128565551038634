import React from "react";
import { pageAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";
import { motion } from "framer-motion";
import { Button, Flex, Image, View, useBreakpointValue } from "@aws-amplify/ui-react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function Fotogalerie() {

    const columns = useBreakpointValue({
        base: 1,
        medium: 2,
        xl: 3,
        xxl: 4,
    });

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >

                <ImageList variant="masonry" cols={columns} gap={20} style={{padding: "20px"}}>
                    {[...Array(62)].map((x, i) =>
                        <ImageListItem key={i}>
                            <img style={{borderRadius: "15px"}}
                                src={`/img/galerie/pic (` + (i + 1) + `).JPG?w=248&fit=crop&auto=format`}
                                srcSet={`/img/galerie/pic (` + (i + 1) + `).JPG?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={"omg cute dog"}
                                loading="lazy"
                            />
                        </ImageListItem>
                    )}
                </ImageList>
            </motion.div>
            <ScrollTop />
        </>
    )
}

export default Fotogalerie